import { __decorate } from "tslib";
import { Vue, Component, Watch, Emit, Prop } from 'vue-property-decorator';
import TemplatesApi from '@/services/api/templates.api';
import TemplatesList from '@/components/shared/TemplatesList.vue';
let TemplatesListModal = class TemplatesListModal extends Vue {
    constructor() {
        super(...arguments);
        this.shownColumns = ['name', 'description'];
        this.templates = [];
        this.loading = true;
        this.search = '';
        this.pagination = {
            limit: 25,
            page: 1,
            total: 0,
        };
    }
    get offset() {
        const { page, limit } = this.pagination;
        return limit * (page - 1);
    }
    mounted() {
        this.fetchTemplates();
    }
    onTemplateSelected(template) {
        this.$refs.modal.close();
        return template;
    }
    updateTemplates() {
        this.$nextTick(() => this.fetchTemplates());
    }
    async fetchTemplates() {
        try {
            this.loading = true;
            const { templates, total } = await TemplatesApi.find({
                organizationId: this.organizationId,
                limit: this.pagination.limit,
                page: this.pagination.page,
                search: this.search,
            });
            this.pagination.total = total;
            this.templates = templates;
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Prop()
], TemplatesListModal.prototype, "organizationId", void 0);
__decorate([
    Emit()
], TemplatesListModal.prototype, "onTemplateSelected", null);
__decorate([
    Watch('pagination.page', { deep: true }),
    Watch('search')
], TemplatesListModal.prototype, "updateTemplates", null);
TemplatesListModal = __decorate([
    Component({
        components: {
            TemplatesList,
        },
    })
], TemplatesListModal);
export default TemplatesListModal;
