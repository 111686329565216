import axios from '@/services/axios-instance';
import { API_URL } from '@/services/api-url/api-url.service';
export default class TemplatesApi {
    static async objectsDescribe(organizationId) {
        const { data } = await axios.get(`${API_URL}/templates/objects-describe`, {
            params: {
                organizationId,
            },
        });
        return data;
    }
    static async create(payload) {
        const { data } = await axios.post(`${API_URL}/templates`, payload);
        return data;
    }
    static async update(id, payload) {
        const { data } = await axios.put(`${API_URL}/templates/${id}`, payload);
        return data;
    }
    static async findById(id) {
        const { data } = await axios.get(`${API_URL}/templates/${id}`);
        return data;
    }
    static async findTemplateDataSets(id) {
        const { data } = await axios.get(`${API_URL}/templates/${id}/data-sets`);
        return data;
    }
    static async findDataSets(params) {
        const { data } = await axios.get(`${API_URL}/templates/data-sets`, {
            params,
        });
        return data;
    }
    static async delete(id) {
        const { data } = await axios.delete(`${API_URL}/templates/${id}`);
        return data;
    }
    static async find(params) {
        const { data } = await axios.get(`${API_URL}/templates`, {
            params,
        });
        return data;
    }
}
