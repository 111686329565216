import { __decorate } from "tslib";
import { Component, Inject, Vue } from 'vue-property-decorator';
import { AwsStorageClass, BackupMode, BackupTime, StorageType } from '@flosum-backup/contracts';
import { required } from 'vuelidate/lib/validators';
import { Validate } from 'vuelidate-property-decorators';
import BackupSettings from '@/components/backups/BackupSettings.vue';
import BackupsApi from '@/services/api/backups.api';
import StoragesApi from '@/services/api/storages.api';
let BackupsCreatePage = class BackupsCreatePage extends Vue {
    constructor() {
        super(...arguments);
        this.name = '';
        this.description = '';
        this.backupOptions = {};
        this.storages = [];
        this.loading = false;
    }
    async mounted() {
        this.loading = true;
        try {
            this.storages = await StoragesApi.findAll();
        }
        catch (error) {
            this.$toast.error(error.message);
            this.$router.replace({
                name: 'backups.index',
            });
        }
        finally {
            this.loading = false;
        }
    }
    async submit() {
        try {
            this.loading = true;
            this.$v.$touch();
            if (this.$v.$anyError) {
                return;
            }
            this.validateSchedulerOptions();
            this.validateTemplateOptions();
            this.validateIncrementalOptions();
            this.validateStorageOptions();
            await BackupsApi.create({
                name: this.name,
                description: this.description,
                options: this.backupOptions,
                organizationId: this.organizationId,
            });
            this.$toast.success('Backup successfully queued');
            this.$router.push({
                name: 'backups',
            });
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
    validateTemplateOptions() {
        if (this.backupOptions.mode === BackupMode.Template) {
            if (!this.backupOptions.templateId) {
                throw new Error('Pick a template to create backup');
            }
        }
    }
    validateIncrementalOptions() {
        if (this.backupOptions.mode === BackupMode.Incremental &&
            this.backupOptions.time === BackupTime.Now &&
            !this.backupOptions.backupId) {
            throw new Error('Pick a backup to create new incremental backup');
        }
    }
    validateStorageOptions() {
        if (!this.backupOptions.storage) {
            throw new Error('Storage in not defined');
        }
        if (!this.backupOptions.storage.id) {
            throw new Error('Pick a storage to save backup');
        }
        const pickedStorage = this.storages.find(storage => storage.id === this.backupOptions.storage.id);
        if (!pickedStorage) {
            throw new Error('Invalid storage');
        }
        if (pickedStorage.type === StorageType.AWS_S3 &&
            (!this.backupOptions.storage.class || !Object.values(AwsStorageClass).includes(this.backupOptions.storage.class))) {
            throw new Error('Invalid storage class');
        }
    }
    validateSchedulerOptions() {
        if (this.backupOptions.time === BackupTime.Repeat) {
            const { scheduler } = this.backupOptions;
            if (!scheduler) {
                throw new Error('Scheduler is not defined');
            }
            if (!scheduler.name) {
                throw new Error('Scheduler should be named');
            }
            if (scheduler.mode === 'weekly' && !scheduler.weekDays.length) {
                throw new Error('Pick at least 1 day to run scheduler');
            }
            if (this.backupOptions.mode === BackupMode.Template && !this.backupOptions.templateId) {
                throw new Error('Pick a template to create scheduled backup');
            }
            if (this.backupOptions.mode === BackupMode.Incremental &&
                !this.backupOptions.backupId &&
                !this.backupOptions.schedulerId) {
                throw new Error('Pick a backup or scheduler to create new scheduler');
            }
            if (!scheduler.time) {
                throw new Error('Pick a time to start scheduler');
            }
        }
    }
};
__decorate([
    Inject()
], BackupsCreatePage.prototype, "organizationId", void 0);
__decorate([
    Validate({ required })
], BackupsCreatePage.prototype, "name", void 0);
__decorate([
    Validate({})
], BackupsCreatePage.prototype, "description", void 0);
BackupsCreatePage = __decorate([
    Component({
        components: {
            BackupSettings,
        },
    })
], BackupsCreatePage);
export default BackupsCreatePage;
