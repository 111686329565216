import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let BackupsList2 = class BackupsList2 extends Vue {
    constructor() {
        super(...arguments);
        this.TABLE_FIELD_MAP = {
            id: { label: 'id' },
            name: { label: 'Name', thStyle: { width: '150px' } },
            description: { label: 'Description' },
            organizationId: { label: 'Organization Id' },
        };
    }
    get tableFields() {
        const fields = [{ key: 'index', label: '#', thStyle: { width: '6%' } }];
        fields.push(...this.shownColumns.map(columnName => {
            return {
                key: columnName,
                ...this.TABLE_FIELD_MAP[columnName],
            };
        }));
        return fields;
    }
};
__decorate([
    Prop({
        required: true,
    })
], BackupsList2.prototype, "templates", void 0);
__decorate([
    Prop({
        default: [],
    })
], BackupsList2.prototype, "shownColumns", void 0);
__decorate([
    Prop({
        default: 0,
    })
], BackupsList2.prototype, "localOffset", void 0);
BackupsList2 = __decorate([
    Component({
        inheritAttrs: false,
    })
], BackupsList2);
export default BackupsList2;
