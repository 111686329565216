import { __decorate } from "tslib";
import { Component, Inject, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { BackupMode, BackupTime } from '@flosum-backup/contracts';
import BackupsListModal from '@/components/shared/BackupsListModal.vue';
import TemplatesListModal from '@/components/shared/TemplatesListModal.vue';
import SchedulerSettings from '@/components/shared/Scheduler.vue';
import SchedulersListModal from '@/components/shared/SchedulersListModal.vue';
import BackupStorageDeployPickControl from '@/components/BackupStorageDeployPickControl.vue';
let BackupSettings = class BackupSettings extends Vue {
    constructor() {
        super(...arguments);
        this.excludeBinaryObjects = false;
        this.excludeBigObjects = false;
        this.selectedMode = BackupMode.Full;
        this.selectedBackupTime = BackupTime.Now;
        this.selectedBackup = null;
        this.selectedTemplate = null;
        this.selectedScheduler = null;
        this.schedulerOptions = {};
        this.schedulerFor = 'backup';
        this.pickedStorage = {
            id: null,
            class: null,
        };
        this.backupTypes = [
            { mode: BackupMode.Full, name: 'Full backup', icon: 'drive', active: true },
            { mode: BackupMode.Template, name: 'Template-based', icon: 'template', active: false },
            { mode: BackupMode.Incremental, name: 'Incremental', icon: 'incremental', active: false },
        ];
    }
    selectBackupType(mode) {
        this.backupTypes.forEach(type => {
            type.active = type.mode === mode;
        });
        this.selectedBackup = null;
        this.selectedTemplate = null;
        this.selectedMode = mode;
        this.excludeBinaryObjects = false;
        this.excludeBigObjects = false;
    }
    onSchedulerForChanged() {
        this.selectedBackup = null;
    }
    onOptionsChanged() {
        this.$emit('changed', {
            mode: this.selectedMode,
            time: this.selectedBackupTime,
            excludeBinaryObjects: this.excludeBinaryObjects,
            excludeBigObjects: this.excludeBigObjects,
            scheduler: this.schedulerOptions,
            storage: {
                id: this.pickedStorage?.id,
                class: this.pickedStorage?.class,
            },
            backupId: this.selectedBackup?.id || null,
            templateId: this.selectedTemplate?.id || null,
            schedulerId: this.selectedScheduler?.id || null,
        });
    }
};
__decorate([
    Inject()
], BackupSettings.prototype, "organizationId", void 0);
__decorate([
    Prop()
], BackupSettings.prototype, "storages", void 0);
__decorate([
    Model('changed')
], BackupSettings.prototype, "options", void 0);
__decorate([
    Watch('schedulerFor')
], BackupSettings.prototype, "onSchedulerForChanged", null);
__decorate([
    Watch('selectedMode', { deep: true, immediate: true }),
    Watch('selectedBackup', { deep: true }),
    Watch('selectedTemplate', { deep: true }),
    Watch('selectedBackupTime', { deep: true }),
    Watch('schedulerOptions', { deep: true }),
    Watch('pickedStorage', { deep: true }),
    Watch('excludeBinaryObjects', { deep: true })
    // @Watch('excludeBigObjects', { deep: true })
    ,
    Watch('pickedStorageClass', { immediate: true })
], BackupSettings.prototype, "onOptionsChanged", null);
BackupSettings = __decorate([
    Component({
        components: {
            BackupStorageDeployPickControl,
            SchedulersListModal,
            Scheduler: SchedulerSettings,
            TemplatesListModal,
            BackupsListModal,
        },
    })
], BackupSettings);
export default BackupSettings;
