import { __decorate } from "tslib";
import { Vue, Component, Emit, Prop, Ref } from 'vue-property-decorator';
import TemplatesList from '@/components/shared/TemplatesList.vue';
import SchedulersList from '@/components/shared/SchedulersList.vue';
let SchedulersListModal = class SchedulersListModal extends Vue {
    onSchedulerSelected(scheduler) {
        this.modal.close();
        return scheduler;
    }
};
__decorate([
    Ref()
], SchedulersListModal.prototype, "modal", void 0);
__decorate([
    Prop()
], SchedulersListModal.prototype, "organizationId", void 0);
__decorate([
    Emit()
], SchedulersListModal.prototype, "onSchedulerSelected", null);
SchedulersListModal = __decorate([
    Component({
        components: {
            SchedulersList,
            TemplatesList,
        },
    })
], SchedulersListModal);
export default SchedulersListModal;
