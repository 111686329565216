import { __decorate } from "tslib";
import { Vue, Component, Model, Watch } from 'vue-property-decorator';
let Scheduler = class Scheduler extends Vue {
    constructor() {
        super(...arguments);
        this.weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        // public readonly monthWeeks = ['1st', '2nd', '3rd', '4th', 'last'];
        this.monthDays = new Array(31).fill(null).map((_, i) => `${i + 1}`);
        this.monthMode = 'day';
        this.body = {
            name: '',
            time: '',
            mode: 'weekly',
            monthDay: '',
            weekDays: [],
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
    }
    toggleWeekDay(weekDay) {
        if (this.body.weekDays.includes(weekDay)) {
            this.body.weekDays = this.body.weekDays.filter(day => day !== weekDay);
        }
        else {
            this.body.weekDays = [...this.body.weekDays, weekDay];
        }
    }
    onModeChanged() {
        [this.body.monthDay] = this.monthDays;
        this.body.weekDays = [];
    }
    onBodyChanged() {
        this.$emit('changed', this.body);
    }
};
__decorate([
    Model('changed')
], Scheduler.prototype, "model", void 0);
__decorate([
    Watch('body.mode', {
        deep: true,
        immediate: true,
    })
], Scheduler.prototype, "onModeChanged", null);
__decorate([
    Watch('body', { deep: true, immediate: true })
], Scheduler.prototype, "onBodyChanged", null);
Scheduler = __decorate([
    Component({})
], Scheduler);
export default Scheduler;
